import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ContactForm from "../components/contact"
import {Container,Row,Col,Card} from 'react-bootstrap'
import SEO from "../components/seo"


const mobileDesktop =()=>{
    return(
    <Layout>
        <SEO title="ERP Software" />
        <div className="homeAuto">
            <Image alt="home automation" filename="erp.png" />
                <div className="erpImage">
                    <h1 style={{fontWeight:"bold",fontSize:"44px"}} className="erphead">Optimize. Track. Grow.</h1>
                    <h3 style={{marginLeft:"-60px"}} className="erpheadpara">
                        Accelerate your company’s growth and productivity with</h3>
                    <h3 style={{marginLeft:"-120px"}} className="erpheadpara">Trace Width’s customized Enterprise Resource Planning solutions</h3>
                </div>
        </div>
        <Container>
            <Row className="ml-1 mt-3">
                <h3 className="erph3">ERP Software Development</h3>
                <p className="manuPara1">Trace Width offers smart Enterprise Resource Planning (ERP) software solutions for your enterprise. If you have employees, customers, and accounts to track, Trace Width ERP solutions will streamline your operations.</p>
            </Row>
            <Row>
                <Col sm={12} lg={4}>
                    <Card className="crmCard">
                        <Image alt="cloud" filename="1c.png" style={{width:"116px"}} />
                        <div className="headerSpan">Easy to use</div> 
                        <div className="crmPara">Intuitive interfaces and quick start guides, ensure user adoption, and productivity from day one</div>
                    </Card>
                </Col>
                <Col sm={12} lg={4}>
                    <Card className="crmCard">
                        <Image alt="cloud" filename="2c.png"  style={{width:"116px"}}/>
                        <div className="headerSpan">Low cost</div> 
                        <div className="crmPara">Procures low TCO for companies regardless of intricacies, scale, or deployment requirements</div>
                    </Card>
                </Col>
                <Col sm={12} lg={4}>
                    <Card className="crmCard">
                        <Image alt="cloud" filename="3c.png"  style={{width:"116px"}}/>
                        <div className="headerSpan">Compliance</div> 
                        <div className="crmPara">Compliance with multiple legislations & accounting practices enable managing global business within one system</div>
                    </Card>
                </Col>
            </Row>

                
             
        </Container>
        <div className="erpForm"> 
            <Container className="erpContainer">
                <p className="manuPara1">By managing your business applications and IT infrastructure for you, Trace Width’s Hosted Services allows you to focus 100% on what’s most important – running and growing your business. We cut the costs of administration, support, maintenance, upgrade, and deployment – all without compromising functionality and your return on investment. Plus there’s minimal start-up time and no requirement for lengthy procurement or technical training.</p>
                <p className="manuPara1">With our flexible, affordable, and application-rich enterprise products, we can help you make the most of your business processes. We analyze relevant data in real time and achieve your targets and goals with great efficiency.</p>
            </Container>
        </div>

        <Container>
            <h3 className="manuh3">Main Features and Functions:</h3>
            <br/>
            <Row>
                <Col sm={6}>
                    <Row>
                        <Col sm={4} className="erpCol"><Image alt="erp" filename="fe1.png"  style={{width:"136px"}}/></Col>
                        <Col sm={8}>
                            <h3 className="hardwareh3">Accounting and Finance</h3>
                            <p className="erppara1">Streamline your core finance operations and capture new growth opportunities.</p>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6}>
                    <Row>
                        <Col sm={4}  className="erpCol"><Image alt="erp" filename="fe2.png"  style={{width:"136px"}}/></Col>
                        <Col sm={8}>
                            <h3 className="hardwareh3">Time and Project Management</h3>
                            <p className="erppara1">Drive the Project Methodically. Administer your project resources end-to-end.</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col sm={6}>
                    <Row>
                        <Col sm={4}  className="erpCol"><Image alt="erp" filename="fe3.png"  style={{width:"136px"}}/></Col>
                        <Col sm={8}>
                            <h3 className="hardwareh3">Human Resources Management</h3>
                            <p className="erppara1">Simplify your cumbersome HR Management processes so that you SAVE TIME for other more
                                                        productive work</p>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6}>
                    <Row>
                        <Col sm={4}  className="erpCol"><Image alt="erp" filename="fe4.png"  style={{width:"136px"}}/></Col>
                        <Col sm={8}>
                            <h3 className="hardwareh3">Customer Relations Management</h3>
                            <p className="erppara1">Close more deals with less time</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col sm={6}>
                    <Row>
                        <Col sm={4}  className="erpCol"><Image alt="erp" filename="fe5.png"  style={{width:"136px"}}/></Col>
                        <Col sm={8}>
                            <h3 className="hardwareh3">Sales & Purchase Management</h3>
                            <p className="erppara1">Eliminate stock outs and order duplication</p>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6}>
                    <Row>
                        <Col sm={4}  className="erpCol"><Image alt="erp" filename="fe6.png"  style={{width:"136px"}}/></Col>
                        <Col sm={8}>
                            <h3 className="hardwareh3">Manufacturing Management</h3>
                            <p className="erppara1">Powering the Rise of Smart and Automated Production</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col sm={6}>
                    <Row>
                        <Col sm={4}  className="erpCol"><Image alt="erp" filename="fe7.png"  style={{width:"136px"}}/></Col>
                        <Col sm={8}>
                            <h3 className="hardwareh3">Mobile Solutions</h3>
                            <p className="erppara1">Simplify technology complexity with state-of-the-art mobility thinking</p>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6}>
                    <Row>
                        <Col sm={4}  className="erpCol"><Image alt="erp" filename="fe8.png"  style={{width:"136px"}}/></Col>
                        <Col sm={8}>
                            <h3 className="hardwareh3">Business Intelligence and Reporting</h3>
                            <p className="erppara1">Provides accurate information for strategic decision making</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>

            
        <div className="headerForm"> 
            <br/>
            <div className="homeCardrow5">The complete ERP solution for your company awaits. Get in touch with us today!</div>
            <ContactForm  />
        </div>
          
    </Layout>
);

}

export default mobileDesktop;

